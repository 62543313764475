import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import SEO from '../components/seo';
import Layout from '../components/layout';

import MasonryGallery from '../components/page/masonryImageGallery';

function shuffle(array) {
  // randomization
  array.sort(() => Math.random() - 0.5);
}
export default function Template({ data, pageContext }) {
  const { site, impressions, page } = data;

  const images = impressions.nodes.map((item) => {
    try {
      return {
        src: item.image.childImageSharp.fluid.src,
        width: item.image.childImageSharp.fluid.presentationWidth,
        height: item.image.childImageSharp.fluid.presentationHeight,
        srcSet: item.image.childImageSharp.fluid.srcSet,
        title: item.titre,
        alt: item.titre,
        key: item.id,
      };
    } catch (error) {
      console.log(error);

      return null;
    }
  }).filter((elt) => elt);
  shuffle(images);
  return (
    <Layout title={`${site.siteMetadata.title} - ${page.nom}`} buildTime={pageContext.buildTime}>
      <SEO meta={page.meta} />
      <Headings
        title={page.nom}
        icon={page.icon}
        description={page.description}
      />
      <div className="row">
        <MasonryGallery photos={images} />
      </div>
    </Layout>
  );
}

const Headings = ({
  title, icon, paragraphs, description,
}) => {
  const logoStyle = {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
  };
  const hStyle = {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  };
  const dStyle = {
    border: '5px',
    borderColor: '#f2bac9',
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.5rem',
    marginTop: '10px',
    marginBottom: '10px',
  };
  return (
    <div className="row mt-3 ">
      <div className="col-8 col-md-8 col-md-pull-4 order-first p-1">
        <div className="container">
          <div className="row">
            {' '}
            <div className="col-4 ">
              <Image
                style={logoStyle}
                className="rounded "
                fluid={icon.childImageSharp.fluid}
                alt={title}
              />
            </div>
            <div className="col-8 align-self-center p-1">
              <h1 style={hStyle}>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <aside className="col-4 col-md-4 col-md-push-8  order-md-2 p-1">
        <div style={dStyle}>{description}</div>
      </aside>
    </div>
  );
};

export const pageQuery = graphql`
  query impressionsQuery {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    page: strapiPageImpressions {
      nom
      description
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      paragraphs {
        nom
        id
        impressions_categories {
          nom
          id
        }
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
            width
            height
            src
          }
          }
        }
      }
    }
    impressions: allStrapiImpressions {
      nodes {
        impressions_categories {
          id
          nom
        }
        titre
        id
        image {
          childImageSharp {
            fluid(maxWidth: 2048) {
              src
              sizes
              srcSet
              presentationHeight
              presentationWidth
            }
          }
          publicURL
        }
      }
    }
  }
`;
